<template>
  <div>
    <!-- Display indicator when page is loading -->
    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="64"
      :width="7"
      color="primary"
      style="position: fixed; top:50%; left:50%"
    ></v-progress-circular>

    <!-- Display a search bar that accepts user input to filter the players shown -->
    <input
      type="text"
      class="searchbar"
      v-model="search"
      placeholder="Search for players by first or last name, screen name, or rookie year"
    />

    <!-- Disply list of players -->
    <ul :v-if="!loading" class="listContainer">
      <li
        v-for="player in filterPlayers"
        :key="player.playerId"
        class="playerItem"
      >
        <v-card @click="goToPlayer(player.playerId)" class="playerCard">
          <div class="logo">
            <img :src="logo(player.favoriteTeamId)" />
          </div>
          <span style="text-align: center; width: 100%">
            {{ player.firstName }} {{ player.lastName }}
          </span>
          <div class="logo" v-for="x of player.championships" :key="x">
            <img src="@/assets/Super-Bowl-Trophy-1.png" />
          </div>
        </v-card>
      </li>
    </ul>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";

export default {
  title: "AZFBF - Player List",
  data() {
    return {
      loading: true,
      search: "",
      players: []
    };
  },
  computed: {
    filterPlayers() {
      return !this.search
        ? this.players
        : this.players.filter(p => {
            const values = Object.values(p)
              .join(",")
              .toLowerCase();
            return values.includes(this.search.toLowerCase());
          });
    }
  },
  methods: {
    logo(id) {
      return id
        ? require("../assets/TeamLogo/" + id + ".png")
        : require("../assets/TeamLogo/0.png");
    },
    goToPlayer(id) {
      this.$router.push({ name: "Player", params: { id: id } });
    }
  },
  created: function() {
    EventService.getPlayersAll().then(response => {
      this.players = response.data;
      this.loading = false;
      // console.log(response.data);
    });
  }
};
</script>

<style scoped>
.searchbar {
  width: 100%;
  padding: 12px 20px 12px 40px;
  margin: 12px 0;
  border-radius: 6px;
  border: 2px solid #fff;
  background-color: rgba(211, 211, 211, 0.473);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
}

.listContainer {
  display: flex;
  flex-flow: row wrap;

  list-style-type: none;
  padding: 0px;
  /* border: 5px solid red; */
}

.playerItem {
  /* border: 1px solid red; */
  width: 25%;
}

.playerCard {
  display: flex;
  align-items: center;
  /* border: 1px solid blueviolet; */
  margin: 5px;
  padding: 5px 10px;
  white-space: nowrap;
}

.logo {
  display: inline-block;
  height: 30px;
}

.logo > img {
  height: 100%;
}

@media (max-width: 960px) {
  .playerItem {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .playerItem {
    width: 100%;
  }
}
</style>
