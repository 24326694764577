// Modified from: // https://github.com/tinloof/gold-stopwatch

<template>
  <div>
    <span class="time" id="display">00:00:00</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startTime: null,
      elapsedTime: 0,
      timerInterval: null,
    };
  },
  methods: {
    timeToString(time) {
      //Convert time to a format of hours, minutues, seconds, and milliseconds
      let diffInHrs = time / 3600000;
      let hh = Math.floor(diffInHrs);

      let diffInMin = (diffInHrs - hh) * 60;
      let mm = Math.floor(diffInMin);

      let diffInSec = (diffInMin - mm) * 60;
      let ss = Math.floor(diffInSec);

      let diffInMs = (diffInSec - ss) * 100;
      let ms = Math.floor(diffInMs);

      let formattedMM = mm.toString().padStart(2, "0");
      let formattedSS = ss.toString().padStart(2, "0");
      let formattedMS = ms.toString().padStart(2, "0");

      return `${formattedMM}:${formattedSS}:${formattedMS}`;
    },

    printToDisplay(txt) {
      document.getElementById("display").innerHTML = txt;
    },

    start() {
      this.startTime = Date.now() - this.elapsedTime;
      this.timerInterval = setInterval(() => {
        this.elapsedTime = Date.now() - this.startTime;
        this.printToDisplay(this.timeToString(this.elapsedTime));
      }, 50);
    },

    pause() {
      clearInterval(this.timerInterval);
    },

    reset() {
      clearInterval(this.timerInterval);
      this.printToDisplay("00:00:00");
      this.elapsedTime = 0;
    },
  },
  created() {
    this.start();
  },
};
</script>

<style></style>
