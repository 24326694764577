<template>
  <div>
    <div id="rotate-device">Rotate device to portrait mode</div>
    <div id="app">
      <!-- Start Page/Menu -->
      <div v-if="gameSession.state == 'pregame'" id="pregame-container">
        <!-- Header-->
        <section id="game-title-container">
          <div id="game-title">
            <h1>NFL Division Challenge</h1>
          </div>
          <div id="game-subtitle">
            <h2>Put each team to the correct division</h2>
          </div>
        </section>

        <form @submit.prevent="startGame()">
          <div>
            <label for="playerName">Player Name: </label>
            <input
              type="text"
              id="playerName"
              v-model="gameState.player"
              placeholder="Enter Player Name"
              autocomplete="name"
              @change="getPlayerBestResult()"
            />
            <span v-if="gameSession.errors" id="error"
              >Player Name Required</span
            >
            <label for="level">Level:</label>
            <select
              v-model="gameState.level"
              id="level"
              @change="
                sortDivisions();
                sortTeams();
                getResults();
              "
            >
              <option
                v-for="level in gameState.levels"
                :value="level.id"
                :key="level.id"
              >
                {{ level.level }}
              </option>
            </select>
          </div>
          <div><button type="submit">Start Game</button></div>
        </form>

        <!-- Show the top 10 results of all time -->
        
        <table>
          <img
            v-if="loadingResults || loadingGame"
            src="../assets/TeamLogo/0.png"
            id="loading-results"
          />
          <caption>
            <div id="game-subtitle">
              <h2>Top 10 All-Time</h2>
            </div>
          </caption>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Time</th>
            <th>Errors</th>
          </tr>
          <tr v-for="(game, i) in gameSession.statHistory_results" :key="i">
            <td>{{ game.rank_allTime }}</td>
            <td>{{ game.player }}</td>
            <td>{{ timeToString(game.time) }}</td>
            <td>{{ game.error }}</td>
          </tr>

          <tr
            v-show="
              gameSession.statHistory_playerTop.length > 0 &&
              gameSession.statHistory_playerTop[0].rank_allTime > 10
            "
          >
            <td style="font-size: 22px; padding: 0px">&#8942;</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr
            v-show="
              gameSession.statHistory_playerTop.length > 0 &&
              gameSession.statHistory_playerTop[0].rank_allTime > 10
            "
            v-for="(game, i) in gameSession.statHistory_playerTop"
            :key="'player' + i"
            id="personal-best"
          >
            <td>{{ game.rank_allTime }}</td>
            <td>{{ game.player }}</td>
            <td>{{ timeToString(game.time) }}</td>
            <td>{{ game.error }}</td>
          </tr>
        </table>

        <!-- Show each players best result -->
        <table>
          <img
            v-if="loadingResults || loadingGame"
            src="../assets/TeamLogo/0.png"
            id="loading-results"
          />
          <caption>
            <div id="game-subtitle">
              <h2>Top Result By Player</h2>
            </div>
          </caption>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Time</th>
            <th>Errors</th>
          </tr>
          <tr v-for="(game, i) in gameSession.statHistory_results_byPlayer" :key="i">
            <td>{{ game.rank_allTime }}</td>
            <td>{{ game.player }}</td>
            <td>{{ timeToString(game.time) }}</td>
            <td>{{ game.error }}</td>
          </tr>
        </table>
      </div>

      <!-- Game Board -->
      <div v-if="gameSession.state == 'game'" class="gameboard">
        <!-- Portion of game board that teams are dragged from  -->
        <section id="from-container">
          <div id="from-title"><h1>Teams</h1></div>
          <ul>
            <li
              v-for="team in gameSession.teams_from"
              :key="team.teamId"
              draggable="true"
              :class="{
                'from-team-touch-selected':
                  gameSession.touch.teamId == team.teamId,
              }"
              @dragstart="startDrag($event, team.teamId)"
              @touchstart.prevent="touchFrom(team.teamId)"
              @click.prevent="touchFrom(team.teamId)"
            >
              <div class="from-logo">
                <img :src="logo(team.logo)" />
              </div>
              <div class="from-team-item">
                {{ team.teamCity }} {{ team.teamName }}
              </div>
            </li>
          </ul>
        </section>

        <!-- Portion of game borad that teams are dragged to -->
        <section id="to-container" @click.alt="sim">
          <div id="to-title">
            <h1>Divisions</h1>
          </div>
          <div id="to-body">
            <div id="to-body-background">
              <ul id="to-division-container">
                <li
                  v-for="division in gameState.divisions"
                  :key="division.divisionsId"
                  :class="{
                    'drop-success':
                      gameSession.ui.dropCorrect == division.divisionId,
                    'drop-fail':
                      gameSession.ui.dropIncorrect == division.divisionId,
                    'droparea-on':
                      gameSession.ui.droparea == division.divisionId,
                    'droparea-off':
                      gameSession.ui.droparea != division.divisionId,
                  }"
                  @dragover.prevent
                  @dragenter.prevent="onEnter($event, division.divisionId)"
                  @drop="onDrop($event, division.divisionId)"
                  @touchstart.prevent="touchTo(division.divisionId)"
                  @click.prevent="touchTo(division.divisionId)"
                >
                  <div class="to-division-title">
                    <h1>{{ division.conference }} {{ division.division }}</h1>
                  </div>
                  <div class="to-division-team">
                    <ul>
                      <li
                        v-for="team in filteredTeam(division.divisionId)"
                        :key="team.teamId"
                      >
                        <div class="to-image-container">
                          <img :src="logo(team.logo)" />
                        </div>
                        <div class="to-team-item">
                          {{ team.teamCity }} {{ team.teamName }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div id="to-footer">
            <div>Correct: {{ gameSession.score["correct"] }}/32</div>
            <div>Errors: {{ gameSession.score.error }}</div>
            <Stopwatch ref="stopwatch" />
            <button type="button" id="quit" @click="endGame(false)">
              Quit
            </button>
          </div>
        </section>

        <!-- Winner view/end of game -->
        <section id="win-overlay" @click="newGame()">
          <img
            v-if="loadingEndGame"
            src="../assets/TeamLogo/0.png"
            id="loading-results"
            style="position: absolute; top: 50%"
          />

          <div id="win-overlay-text">
            <!-- Winner!!! <br /> -->
            <!-- {{ this.gameState.player }} <br /> -->
            Time: {{ gameSession.elapsedTime_String }} <br />
            Errors: {{ gameSession.score["error"] }} <br />
            Rank: {{ this.gameSession.result.ranking }} of
            {{ this.gameSession.result.totalGames }}
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Stopwatch from "@/components/Stopwatch.vue";
import VueConfetti from "vue-confetti";
import Vue from "vue";
import EventService from "@/services/EventService.js";
import { cloneDeep } from "lodash";
import { reject } from "q";

Vue.use(VueConfetti);

export default {
  components: {
    Stopwatch,
  },
  data() {
    return {
      loadingResults: false,
      loadingGame: false,
      loadingEndGame: false,
      gameState: {
        player: "",
        level: 20,
        levels: [
          { id: 10, level: "Easy" },
          {
            id: 20,
            level: "Medium - Teams Random",
          },
          { id: 30, level: "Hard - Divisions Random" },
        ],
        divisions: [],
        teams: [],
      },

      gameSession: {
        state: "pregame", //states are pregame, game
        errors: false,
        result: {},
        touch: {
          teamId: null,
        },
        ui: {
          droparea: 0,
          dropCorrect: 0,
          dropIncorrect: 0,
        },
        teams_from: [],
        teams_to: [],
        score: { correct: 0, error: 0 },
        elapsedTime: 0,
        elapsedTime_String: null,
        statHistory_results: [],
        statHistory_results_byPlayer: [],
        statHistory_playerTop: [],
      },
    };
  },

  methods: {
    newGame() {
      this.loading = true;

      // Stop Confetti
      this.$confetti.stop();

      //Reset session data to initial data, keep current gameState data
      Object.assign(this.$data, {
        ...this.$options.data(),
        gameState: this.gameState,
      });

      //Get results to be displayed
      this.getResults();

      //Sort the divisions based on game level
      this.sortDivisions();

      //Sort the teams based on game level
      this.sortTeams();

      this.loading = false;
    },

    sortDivisions() {
      if (this.gameState.level >= 30) {
        this.gameState.divisions = this.gameState.divisions
          .map((d) => {
            d["sort"] = Math.random();
            if (d.conference == "AFC") d.sort += 1;
            return d;
          })
          .sort((a, b) => a.sort - b.sort);
      } else {
        this.gameState.divisions.sort((a, b) => a.divisionId - b.divisionId);
      }
    },

    sortTeams() {
      //Set order of  NFL teams list based on game level selected
      if (this.gameState.level >= 20) {
        //Sort teams randomly
        this.gameSession.teams_from = this.gameState.teams
          .map((t) => {
            t["sort"] = Math.random();
            return t;
          })
          .sort((a, b) => a.sort - b.sort);
      } else {
        // sort teams by team city
        this.gameSession.teams_from = cloneDeep(this.gameState.teams).sort(
          (a, b) => {
            if (a.teamCity < b.teamCity) return -1;
            return 1;
          }
        );
      }
    },

    sim() {
      for (let i = this.gameSession.teams_from.length - 1; i > 0; i--) {
        this.gameSession.teams_to.push(this.gameSession.teams_from[i]);
        this.gameSession.teams_from.splice(i, 1);
      }

      //Lets see if this is how Joe is Cheating
      EventService.game_insert({
        player: "sim",
        time: this.gameSession.elapsedTime,
        correct: this.gameSession.score.correct,
        error: this.gameSession.score["error"],
        win: false,
        level: this.gameState.level,
        datetime: new Date().toISOString().slice(0, 19).replace("T", " "),
        userAgent: window.navigator.userAgent.slice(0, 399),
      });
    },

    startGame() {
      if (this.gameState.player.length == 0) {
        this.gameSession.errors = true;
        return;
      }
      this.gameSession.state = "game";
    },

    onEnter(ev, d) {
      ev.dataTransfer.dropEffect = "move";

      //Set area that should be indicated for drop
      this.gameSession.ui.droparea = d;
    },

    touchFrom(teamId) {
      this.gameSession.touch.teamId = teamId;
      this.gameSession.ui.dropCorrect = 0;
      this.gameSession.ui.dropIncorrect = 0;
    },

    touchTo(divisionId) {
      if (this.gameSession.touch.teamId != null) {
        this.checkMove(this.gameSession.touch.teamId, divisionId);
      }
    },

    startDrag(ev, teamId) {
      //Set Drag Data
      ev.dataTransfer.setData("teamId", teamId);
      ev.dataTransfer.effectAllowed = "move";

      //Reset data that sets classes on drop to indicate if droped in the correct place
      this.gameSession.ui.dropIncorrect = 0;
      this.gameSession.ui.dropCorrect = 0;
    },

    onDrop(ev, divisionId) {
      //Get Data for team that is being dragged
      const teamId = parseInt(ev.dataTransfer.getData("teamId"));

      this.checkMove(teamId, divisionId);
    },

    checkMove(teamId, divisionId) {
      //Function checks to so if team was moved to the correct division
      const team = this.gameSession.teams_from.filter(
        (t) => t.teamId == teamId
      )[0];

      if (team.divisionId != divisionId) {
        this.gameSession.score.error++;
        this.gameSession.touch.teamId = null;
        this.gameSession.ui.dropIncorrect = divisionId; //Show visual feedback of incorrect drop
        return false;
      }

      //Moved to correct Division
      //Add to diviion list (teams_to)
      this.gameSession.teams_to.push(team);

      //Remove from teams-from list
      const location = this.gameSession.teams_from
        .map((e) => e.teamId)
        .indexOf(teamId);
      this.gameSession.teams_from.splice(location, 1);
      this.gameSession.touch.teamId = null; //Clear the team selected for touch

      //Hide border on border indicating place to drop
      this.gameSession.ui.droparea = 0;
      this.gameSession.ui.dropCorrect = divisionId;

      //Increment score counter
      this.gameSession.score.correct++;
      //If there are more teams to place, then exit
      if (this.gameSession.teams_from.length != 0) return;

      //Winner
      this.endGame(true);
    },

    endGame(win) {
      this.loadingEndGame = true;
      //Pause stopwatch and collect Data that will be displayed and/or saved
      this.$refs.stopwatch.pause();
      this.gameSession.elapsedTime = this.$refs.stopwatch.elapsedTime;
      this.gameSession.elapsedTime_String = this.$refs.stopwatch.timeToString(
        this.$refs.stopwatch.elapsedTime
      );

      // Celebrate with User
      if (win) {
        document.getElementById("win-overlay").style.display = "block";
        this.$confetti.start();
      }

      //Save Data
      const insertResult = EventService.game_insert({
        player: this.gameState.player.trim(),
        time: this.gameSession.elapsedTime,
        correct: this.gameSession.score.correct,
        error: this.gameSession.score["error"],
        win: win,
        level: this.gameState.level,
        datetime: new Date().toISOString().slice(0, 19).replace("T", " "),
        userAgent: window.navigator.userAgent.slice(0, 399),
      });

      //Display results if you won else start new game/go to start menu
      if (win) {
        insertResult
          .then(() => {
            return EventService.customQuery({
              sql:
                `Select ranking, 
                (select count(*) from game_NFL_results where level = ` +
                this.gameState.level +
                `) totalGames
                      from (Select time, rank()over(order by time asc) ranking
                        from game_NFL_results
                        where win = 1 
                        and level = ` +
                this.gameState.level +
                `
                      ) data
                    where data.time = ` +
                this.gameSession.elapsedTime +
                ` limit 1`,
            });
          })
          .then((response) => {
            this.gameSession.result = response.data[0];
            document.getElementById("win-overlay-text").style.display = "block";
            this.loadingEndGame = false;
          });
      } else {
        this.loadingEndGame = false;
        this.newGame();
      }
    },

    filteredTeam(divisionId) {
      return this.gameSession.teams_to.filter(
        (t) => t.divisionId == divisionId
      );
    },

    logo(id) {
      return id
        ? require("../assets/TeamLogo/" + id)
        : require("../assets/TeamLogo/0.png");
    },

    timeToString(time) {
      //Convert time to a format of hours, minutues, seconds, and milliseconds
      let diffInHrs = time / 3600000;
      let hh = Math.floor(diffInHrs);

      let diffInMin = (diffInHrs - hh) * 60;
      let mm = Math.floor(diffInMin);

      let diffInSec = (diffInMin - mm) * 60;
      let ss = Math.floor(diffInSec);

      let diffInMs = (diffInSec - ss) * 100;
      let ms = Math.floor(diffInMs);

      let formattedMM = mm.toString().padStart(2, "0");
      let formattedSS = ss.toString().padStart(2, "0");
      let formattedMS = ms.toString().padStart(2, "0");

      return `${formattedMM}:${formattedSS}:${formattedMS}`;
    },

    getGameData() {
      return new Promise((resolve, reject) => {
        const sqlTeams = { sql: `select * from game_NFL_teams` };
        const sqlDivisions = { sql: `select * from game_NFL_divisions` };

        Promise.all([
          EventService.customQuery(sqlTeams),
          EventService.customQuery(sqlDivisions),
        ])
          .then(([{ data: teams }, { data: divisions }]) => {
            this.gameState.teams = teams;
            this.gameState.divisions = divisions;
            resolve();
          })
          .catch((error) => reject(error));
      });
    },

    getPlayerBestResult() {
      //Sql for players best time
      const sqlMeTop = {
        sql:
          `select * 
          from
            (select player, time, error, rank() over (order by time asc) rank_allTime 
            from game_NFL_results where win = 1 and level = ` +
          this.gameState.level +
          `) data
          where player = '` +
          this.gameState.player +
          `'
          limit 1`,
      };

      EventService.customQuery(sqlMeTop).then(
        (response) => (this.gameSession.statHistory_playerTop = response.data)
      );
    },

    getResults() {
      this.loadingResults = true;

      //Sql for top 10
      const sql_top10 = {
        sql:
          `select * from
                (select player, time, error, rank() over (order by time asc) rank_allTime 
                from game_NFL_results where win = 1 and level = ` +
          this.gameState.level +
          `) data
                where rank_allTime <=10`
      };

      //Sql for top players
      const sql_top_player = {
        sql:
          `select * from
                (select player, time, error, rank() over (order by time asc) rank_allTime 
                from game_NFL_results where win = 1 and level = ` +
          this.gameState.level +
          `) data
          group by player`
      };

      Promise.all([
      EventService.customQuery(sql_top10),
      EventService.customQuery(sql_top_player)
      ])
        .then(([{data:top10},{data: top_player}]) => {
          this.gameSession.statHistory_results = top10;
          this.gameSession.statHistory_results_byPlayer = top_player;
      
          this.loadingResults = false;
        })
        .catch((error) => reject(error))

      this.getPlayerBestResult();
    },
  },

  created() {
    this.loadingGame = true;
    this.getGameData().then(() => {
      this.loadingGame = false;
      this.newGame();
    });
  },

  mounted() {
    var vm = this;

    //Watch for drag end show we stop highlighting the drop area
    window.addEventListener(
      "dragend",
      function (event) {
        event.preventDefault();
        vm.gameSession.ui.droparea = 0;
      },
      false
    );
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  font-family: Verdana, Georgia, sans-serif;
  font-size: 12px;
}

#rotate-device {
  display: none;
}

#game-title-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 25px;
}

#game-title {
  width: 100%;
  text-align: center;
}

#game-title h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

#game-subtitle h2 {
  margin: 0px;
  font-weight: normal;
  font-size: 1.2em;
}

#pregame-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

#pregame-container form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding-top: 15px;
}

label[for="playerName"] {
  display: block;
  text-align: left;
  margin-left: 10px;
}

#error {
  color: red;
  display: block;
  text-align: left;
  margin-left: 10px;
}

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  /* display: inline-block; */
  border: 1px solid #ccc;
  border-radius: 4px;
}

label[for="level"] {
  display: block;
  text-align: left;
  margin-left: 10px;
}

select[id="level"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#pregame-container form > div {
  margin: 10px;
}

button[type="submit"] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin-top: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

#loading-results {
  height: 30px;
  animation-name: loading-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#pregame-container table {
  border: 1px solid lightgray;
  margin-top: 15px;
  border-radius: 10px;
  padding: 10px;
}

#pregame-container td {
  padding: 5px;
}

#pregame-container tr:nth-child(even) {
  background-color: rgb(241, 242, 243);
}

.gameboard {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 960px;
  margin: 0 auto;
}

#from-container {
  display: flex;
  width: 20%;
  flex-direction: column;
}

#from-title {
  width: 100%;
  height: 40px;
  text-align: center;
  border-bottom: 2px solid rgb(241, 242, 243);
}

#from-container ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  padding: 3px 0 0 0;
  margin: 0px;
  width: 100%;
}

#from-container li {
  display: flex;
  /* padding: 10px; */
  cursor: grab;
  width: 100%;
  text-align: left;
  max-height: 30px;
  border-radius: 3px;
}

.from-team-item {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.from-logo {
  height: 30px;
}

.from-logo img {
  padding: 3px;
  height: 100%;
  /* background: lightblue; */
}

#from-container li:nth-child(odd) {
  background-color: rgb(241, 242, 243);
}

#from-container li:nth-child(even) {
  background-color: white;
}

.from-team-touch-selected {
  border: 1px solid grey;
  background-color: lightgreen !important;
  white-space: nowrap;
  overflow: clip;
}

.division p {
  padding: 0px;
  margin: 6px 0px;
}

#to-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  /* border: 1px dotted red; */
}

#to-title {
  width: 100%;
  height: 40px;
  text-align: center;
  border-bottom: 2px solid rgb(241, 242, 243);
}

#to-body {
  margin: 0px;
  padding: 0px;
}

#to-body-background {
  background: rgb(241, 242, 243);
  border-radius: 15px;
  margin: 3px;
  padding: 0px;
}

#to-division-container {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  margin: 0px;
  padding: 0px;
  min-height: 120px;
}

#to-division-container > li {
  width: 25%;
}

.to-division-title {
  text-align: left;
  padding-left: 10px;
}

.to-division-team ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 150px;
  padding-left: 10px;
}

.to-division-team li {
  display: flex;
  width: 100%;
  padding: 5px 0px;
  height: 30px;
}

.to-image-container {
  height: 20px;
}

.to-division-team img {
  height: 100%;
  padding-right: 3px;
}

.to-team-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#to-footer {
  display: flex;
  justify-content: space-around;
  margin: 3px;
  padding: 8px 0;
  border: 1px solid rgb(241, 242, 243);
  border-radius: 10px;
}

#quit {
  border: none;
  color: #af4c4c;
  background: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 2 5px;
}

#quit:hover {
  color: white;
  background-color: #af4c4c;
}

.droparea-on {
  border: 2px solid green;
  border-radius: 15px;
}
.droparea-off {
  border: 2px solid rgb(241, 242, 243);
  /* border: 2px solid lightgray; */
  border-radius: 15px;
}

.drop-success {
  animation-name: correct;
  animation-duration: 0.75s;
  animation-iteration-count: 1;
}

.drop-fail {
  animation-name: incorrect;
  animation-duration: 0.5s;
  animation-iteration-count: 3;
}

#win-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

#win-overlay-text {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}

@keyframes correct {
  0% {
    border: 2px solid white;
  }
  25% {
    border: 2px solid darkgreen;
  }
  /* 50% {
    border: 4px solid darkgreen;
  } */
  /* 75% {
    border: 4px solid darkgreen;
  } */
  100% {
    border: 2px solid rgb(241, 242, 243);
  }
}

@keyframes incorrect {
  0% {
    border: 2px solid rgb(241, 242, 243);
  }
  25% {
    border: 2px solid red;
  }
  50% {
    border: 2px solid rgb(241, 242, 243);
  }
  75% {
    border: 2px solid red;
  }
  100% {
    border: 2px solid rgb(241, 242, 243);
  }
}

@media (max-width: 600px) and (orientation: portrait) {
  * {
    font-size: 12px;
  }

  #playerName {
    font-size: 16px;
  }

  select[id="level"] {
    font-size: 16px;
  }

  .gameboard {
    flex-direction: column;
  }

  #from-container {
    width: 100%;
    height: 170px;
    overflow: hidden;
  }

  #from-title {
    height: auto;
  }
  #from-title h1 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  #to-container {
    margin-top: 30px;
    width: 100%;
  }

  #to-title {
    height: auto;
  }
  #to-title h1 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  #from-container li {
    max-height: 40px;
  }

  .from-team-item {
    font-size: 16px;
  }

  .to-division-title {
    padding-left: 0px;
    text-align: center;
  }
  .to-division-title h1 {
    white-space: nowrap;
    font-weight: normal;
  }

  .to-team-item {
    display: none;
  }

  .to-division-team ul {
    flex-flow: row wrap;
    align-items: flex-start;
    height: 75px;
    /* padding-left: 10px; */
  }

  .to-division-team li {
    display: flex;
    width: 50%;
    padding: 5px 0px;
    height: 30px;
  }

  #win-overlay-text {
    font-size: 30px;
  }
}

@media (max-width: 1000px) and (max-height: 600px) and (orientation: landscape) {
  #rotate-device {
    display: block;
  }

  #app {
    display: none;
  }
}
</style>
