<template>
  <div>
    <amplify-authenticator username-alias="email">
      <!-- <div v-if="authState === 'signedin' && user">
        <div>Hello, {{ user.username }}</div>
      </div> -->

      <amplify-sign-up
        slot="sign-up"
        username-alias="email"
        :formFields.prop="formFields"
      >
      </amplify-sign-up>

      <!-- <amplify-sign-out></amplify-sign-out> -->
    </amplify-authenticator>
  </div>
</template>

<script>
import "@aws-amplify/ui-vue";

export default {
  data() {
    return {
      formFields: [{ type: "email" }, { type: "password" }]
    };
  }
  // ,
  // beforeDestroy() {
  //   this.unsubscribeAuth();
  // }
};
</script>

<style></style>
