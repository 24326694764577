<template>
  <div>
    <!-- Display News List -->
    <section class="container">
      <div v-for="(news, i) in news" :key="i" class="item">
        <v-card @click.stop="showNews(news.articleId)" style="height:100%">
          <v-card-title
            >{{ news.title }}
            <v-spacer></v-spacer>
            <v-icon
              v-if="loggedIn"
              color="red"
              @click.native.stop="
                selectedNewsArticle = news.articleId;
                editNewsDialog = true;
              "
              >mdi-square-edit-outline</v-icon
            >
          </v-card-title>
          <v-card-subtitle>{{ news.subtitle }}</v-card-subtitle>
          <v-card-text>
            {{ news.headline }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="footer">{{ news.author }}</v-card-text>
        </v-card>
      </div>
    </section>

    <!-- Display News article in dialog-->
    <v-dialog v-model="displayNewsDialog" max-width="700px">
      <v-card class="mx-auto ">
        <v-card-title
          >{{ newsSelected.title }}
          <v-spacer></v-spacer>
          <v-btn
            text
            style="position:absolute; z-index: 100; top:10px; right: 0"
            @click="displayNewsDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-subtitle>{{ newsSelected.subtitle }}</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text style="margin-top: 10px">
          <v-textarea v-model="newsSelected.article" solo readonly auto-grow>
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="footer">{{ newsSelected.author }}</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit article -->
    <v-dialog v-model="editNewsDialog" persistent>
      <news-edit
        :editing="true"
        :article="newsSelected"
        @close="editNewsDialog = false"
        @updated="
          editNewsDialog = false;
          fetchData();
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
import { authComputed } from "../store/helpers.js";
import newsEdit from "@/components/News_Edit";

export default {
  title: "AZFBF - News",
  components: {
    newsEdit
  },
  data() {
    return {
      news: [],
      displayNewsDialog: false,
      editNewsDialog: false,
      selectedNewsArticle: null
    };
  },
  computed: {
    newsSelected() {
      return (
        this.news.filter(e => e.articleId == this.selectedNewsArticle)[0] || {}
      );
    },
    ...authComputed
  },
  methods: {
    fetchData() {
      EventService.article_query().then(response => {
        this.news = response.data;
      });
    },
    showNews(id) {
      this.selectedNewsArticle = id;
      this.displayNewsDialog = !this.displayNewsDialog;
    }
  },
  created() {
    this.fetchData();
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: row wrap;
}
.item {
  width: 50%;
  padding: 10px;
}

.footer {
  padding: 8px 15px;
}

@media (max-width: 960px) {
  .item {
    width: 100%;
  }
}
</style>
