<template>
  <div>
    <!-- Dialog to add new article -->
    <!-- <v-dialog v-model="addArticleDialog" persistent max-width="700"> -->

    <!-- Progress bar to show user when updated DB -->
    <v-progress-circular
      v-if="processing"
      class="processing"
      indeterminate
      color="primary"
      style="position: fixed; top:50%; left:50%"
    ></v-progress-circular>

    <!-- Display article information that can be updated -->
    <v-card>
      <v-card-title>
        <v-icon class="mr-3">mdi-note-text-outline</v-icon>
        <span v-if="editing">Edit Article</span>
        <span v-else>New Article</span>
        <v-spacer>
          <v-btn
            text
            style="position:absolute; z-index: 100; top:10px; right: 0"
            @click="cancel()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-spacer>
      </v-card-title>

      <v-card-text>
        <v-form ref="formArticle">
          <v-select
            :items="articleTypes"
            item-text="display"
            item-value="value"
            label="Type"
            :rules="rules.type"
            v-model="article.type"
            @change="pendingChanges = true"
          >
          </v-select>

          <v-text-field
            label="Title "
            v-model="article.title"
            :rules="rules.title"
            :counter="45"
            @change="pendingChanges = true"
          >
          </v-text-field>

          <v-text-field
            label="Subtitle "
            v-model="article.subtitle"
            :rules="rules.subtitle"
            :counter="45"
            @change="pendingChanges = true"
          >
          </v-text-field>

          <v-text-field
            label="Headline "
            v-model="article.headline"
            :rules="rules.headline"
            :counter="100"
            @change="pendingChanges = true"
          >
          </v-text-field>

          <v-text-field
            label="Author "
            v-model="article.author"
            :rules="rules.author"
            :counter="45"
            @change="pendingChanges = true"
          >
          </v-text-field>

          <v-text-field
            label="Season "
            v-model="article.season"
            :rules="rules.season"
            :counter="4"
            @change="pendingChanges = true"
          >
          </v-text-field>

          <v-text-field
            label=" Display Order "
            v-model="article.displayOrder"
            :rules="rules.order"
            @change="pendingChanges = true"
          >
          </v-text-field>

          <v-textarea
            label="Article"
            auto-grow
            v-model="article.article"
            :rules="rules.article"
            @change="pendingChanges = true"
          >
          </v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions style="margin: 0 50px">
        <v-btn v-if="!editing" color="success" @click="addArticle()"
          >Add Article</v-btn
        >
        <v-btn v-if="editing" color="success" @click="updateArticle()"
          >Save Changes</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="error" @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
export default {
  props: {
    editing: { required: true, type: Boolean },
    article: { required: true, type: Object }
  },
  data() {
    return {
      pendingChanges: false,
      processing: false,
      articleTypes: [
        { value: "seasonRecap", display: "Season Recap" },
        { value: "weeklyArticle", display: "Weekly Article" },
        { value: "other", display: "Other" }
      ],
      rules: {
        type: [v => !!v || "Type is required"],
        title: [
          v => !!v || "Title is required",
          v => (v && v.length <= 45) || "Title must be 45 characters or less"
        ],
        subtitle: [
          v => !!v || "Subtitle is required",
          v => (v && v.length <= 45) || "Subtitle must be 45 characters or less"
        ],
        headline: [
          v => !!v || "Headline is required",
          v =>
            (v && v.length <= 100) || "Headline must be 100 characters or less"
        ],
        author: [
          v => !!v || "Author is required",
          v => (v && v.length <= 45) || "Author must be 45 characters or less"
        ],
        season: [
          v => !!v || "Season is required",
          v => (v > 1901 && v < 2155) || "Season value incorrect"
        ],
        article: [v => !!v || "Article is required"],
        order: [
          v => !!v || "Order is required",
          v => (v >= 0 && v < 1000) || "Order should be between 0 and 999"
        ]
      }
    };
  },
  methods: {
    addArticle() {
      //Validate all fields have been filled out properly.  If not, put message in snackbar and exit
      if (!this.$refs.formArticle.validate()) {
        this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "validation1");
        return;
      }

      //Trigger processing indicator to use r
      this.processing = true;

      //Add the article to DB
      EventService.article_insert(this.article)
        .then(() => {
          this.processing = false;
          this.pendingChanges = false;
          this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "success1");
          this.$router.push({ name: "News" });
        })
        .catch(error => {
          console.log("There was an error: " + error.response);
          this.processing = false;
          this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "error1");
        });
    },

    updateArticle() {
      //Validate all fields have been filled out properly.  If not, put message in snackbar and exit
      if (!this.$refs.formArticle.validate()) {
        this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "validation1");
        return;
      }

      //Trigger processing indicator to user
      this.processing = true;

      //Update the article in the DB
      EventService.article_update(this.article)
        .then(() => {
          this.processing = false;
          this.pendingChanges = false;
          this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "success1");
          this.$emit("updated");
        })
        .catch(error => {
          console.log("There was an error: " + error);
          this.processing = false;
          this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "error1");
        });
    },
    cancel() {
      if (this.pendingChanges) {
        var r = confirm(
          "There are pending Changes.  Are you sure you want to exit?"
        );
        if (r) {
          this.pendingChanges = false;
          if (this.editing) this.$emit("close");
          else this.$router.push({ name: "News" });
        }
      } else {
        this.pendingChanges = false;
        if (this.editing) this.$emit("close");
        else this.$router.push({ name: "News" });
      }
    }
  }
};
</script>

<style></style>
