var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"rotate-device"}},[_vm._v("Rotate device to portrait mode")]),_c('div',{attrs:{"id":"app"}},[(_vm.gameSession.state == 'pregame')?_c('div',{attrs:{"id":"pregame-container"}},[_vm._m(0),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.startGame()}}},[_c('div',[_c('label',{attrs:{"for":"playerName"}},[_vm._v("Player Name: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gameState.player),expression:"gameState.player"}],attrs:{"type":"text","id":"playerName","placeholder":"Enter Player Name","autocomplete":"name"},domProps:{"value":(_vm.gameState.player)},on:{"change":function($event){return _vm.getPlayerBestResult()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.gameState, "player", $event.target.value)}}}),(_vm.gameSession.errors)?_c('span',{attrs:{"id":"error"}},[_vm._v("Player Name Required")]):_vm._e(),_c('label',{attrs:{"for":"level"}},[_vm._v("Level:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.gameState.level),expression:"gameState.level"}],attrs:{"id":"level"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.gameState, "level", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.sortDivisions();
              _vm.sortTeams();
              _vm.getResults();}]}},_vm._l((_vm.gameState.levels),function(level){return _c('option',{key:level.id,domProps:{"value":level.id}},[_vm._v(" "+_vm._s(level.level)+" ")])}),0)]),_vm._m(1)]),_c('table',[(_vm.loadingResults || _vm.loadingGame)?_c('img',{attrs:{"src":require("../assets/TeamLogo/0.png"),"id":"loading-results"}}):_vm._e(),_vm._m(2),_vm._m(3),_vm._l((_vm.gameSession.statHistory_results),function(game,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(game.rank_allTime))]),_c('td',[_vm._v(_vm._s(game.player))]),_c('td',[_vm._v(_vm._s(_vm.timeToString(game.time)))]),_c('td',[_vm._v(_vm._s(game.error))])])}),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.gameSession.statHistory_playerTop.length > 0 &&
            _vm.gameSession.statHistory_playerTop[0].rank_allTime > 10
          ),expression:"\n            gameSession.statHistory_playerTop.length > 0 &&\n            gameSession.statHistory_playerTop[0].rank_allTime > 10\n          "}]},[_c('td',{staticStyle:{"font-size":"22px","padding":"0px"}},[_vm._v("⋮")]),_c('td'),_c('td'),_c('td')]),_vm._l((_vm.gameSession.statHistory_playerTop),function(game,i){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.gameSession.statHistory_playerTop.length > 0 &&
            _vm.gameSession.statHistory_playerTop[0].rank_allTime > 10
          ),expression:"\n            gameSession.statHistory_playerTop.length > 0 &&\n            gameSession.statHistory_playerTop[0].rank_allTime > 10\n          "}],key:'player' + i,attrs:{"id":"personal-best"}},[_c('td',[_vm._v(_vm._s(game.rank_allTime))]),_c('td',[_vm._v(_vm._s(game.player))]),_c('td',[_vm._v(_vm._s(_vm.timeToString(game.time)))]),_c('td',[_vm._v(_vm._s(game.error))])])})],2),_c('table',[(_vm.loadingResults || _vm.loadingGame)?_c('img',{attrs:{"src":require("../assets/TeamLogo/0.png"),"id":"loading-results"}}):_vm._e(),_vm._m(4),_vm._m(5),_vm._l((_vm.gameSession.statHistory_results_byPlayer),function(game,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(game.rank_allTime))]),_c('td',[_vm._v(_vm._s(game.player))]),_c('td',[_vm._v(_vm._s(_vm.timeToString(game.time)))]),_c('td',[_vm._v(_vm._s(game.error))])])})],2)]):_vm._e(),(_vm.gameSession.state == 'game')?_c('div',{staticClass:"gameboard"},[_c('section',{attrs:{"id":"from-container"}},[_vm._m(6),_c('ul',_vm._l((_vm.gameSession.teams_from),function(team){return _c('li',{key:team.teamId,class:{
              'from-team-touch-selected':
                _vm.gameSession.touch.teamId == team.teamId,
            },attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.startDrag($event, team.teamId)},"touchstart":function($event){$event.preventDefault();return _vm.touchFrom(team.teamId)},"click":function($event){$event.preventDefault();return _vm.touchFrom(team.teamId)}}},[_c('div',{staticClass:"from-logo"},[_c('img',{attrs:{"src":_vm.logo(team.logo)}})]),_c('div',{staticClass:"from-team-item"},[_vm._v(" "+_vm._s(team.teamCity)+" "+_vm._s(team.teamName)+" ")])])}),0)]),_c('section',{attrs:{"id":"to-container"},on:{"click":function($event){if(!$event.altKey){ return null; }return _vm.sim($event)}}},[_vm._m(7),_c('div',{attrs:{"id":"to-body"}},[_c('div',{attrs:{"id":"to-body-background"}},[_c('ul',{attrs:{"id":"to-division-container"}},_vm._l((_vm.gameState.divisions),function(division){return _c('li',{key:division.divisionsId,class:{
                  'drop-success':
                    _vm.gameSession.ui.dropCorrect == division.divisionId,
                  'drop-fail':
                    _vm.gameSession.ui.dropIncorrect == division.divisionId,
                  'droparea-on':
                    _vm.gameSession.ui.droparea == division.divisionId,
                  'droparea-off':
                    _vm.gameSession.ui.droparea != division.divisionId,
                },on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();return _vm.onEnter($event, division.divisionId)},"drop":function($event){return _vm.onDrop($event, division.divisionId)},"touchstart":function($event){$event.preventDefault();return _vm.touchTo(division.divisionId)},"click":function($event){$event.preventDefault();return _vm.touchTo(division.divisionId)}}},[_c('div',{staticClass:"to-division-title"},[_c('h1',[_vm._v(_vm._s(division.conference)+" "+_vm._s(division.division))])]),_c('div',{staticClass:"to-division-team"},[_c('ul',_vm._l((_vm.filteredTeam(division.divisionId)),function(team){return _c('li',{key:team.teamId},[_c('div',{staticClass:"to-image-container"},[_c('img',{attrs:{"src":_vm.logo(team.logo)}})]),_c('div',{staticClass:"to-team-item"},[_vm._v(" "+_vm._s(team.teamCity)+" "+_vm._s(team.teamName)+" ")])])}),0)])])}),0)])]),_c('div',{attrs:{"id":"to-footer"}},[_c('div',[_vm._v("Correct: "+_vm._s(_vm.gameSession.score["correct"])+"/32")]),_c('div',[_vm._v("Errors: "+_vm._s(_vm.gameSession.score.error))]),_c('Stopwatch',{ref:"stopwatch"}),_c('button',{attrs:{"type":"button","id":"quit"},on:{"click":function($event){return _vm.endGame(false)}}},[_vm._v(" Quit ")])],1)]),_c('section',{attrs:{"id":"win-overlay"},on:{"click":function($event){return _vm.newGame()}}},[(_vm.loadingEndGame)?_c('img',{staticStyle:{"position":"absolute","top":"50%"},attrs:{"src":require("../assets/TeamLogo/0.png"),"id":"loading-results"}}):_vm._e(),_c('div',{attrs:{"id":"win-overlay-text"}},[_vm._v(" Time: "+_vm._s(_vm.gameSession.elapsedTime_String)+" "),_c('br'),_vm._v(" Errors: "+_vm._s(_vm.gameSession.score["error"])+" "),_c('br'),_vm._v(" Rank: "+_vm._s(this.gameSession.result.ranking)+" of "+_vm._s(this.gameSession.result.totalGames)+" ")])])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"game-title-container"}},[_c('div',{attrs:{"id":"game-title"}},[_c('h1',[_vm._v("NFL Division Challenge")])]),_c('div',{attrs:{"id":"game-subtitle"}},[_c('h2',[_vm._v("Put each team to the correct division")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{attrs:{"type":"submit"}},[_vm._v("Start Game")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('caption',[_c('div',{attrs:{"id":"game-subtitle"}},[_c('h2',[_vm._v("Top 10 All-Time")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("Rank")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Time")]),_c('th',[_vm._v("Errors")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('caption',[_c('div',{attrs:{"id":"game-subtitle"}},[_c('h2',[_vm._v("Top Result By Player")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("Rank")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Time")]),_c('th',[_vm._v("Errors")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"from-title"}},[_c('h1',[_vm._v("Teams")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"to-title"}},[_c('h1',[_vm._v("Divisions")])])}]

export { render, staticRenderFns }